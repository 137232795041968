<template>
    <v-app>
        <Header :listMenu="listMenu" />
        <router-view></router-view>
    </v-app>
</template>
<script>
import { Header } from '../../components/organisms'
export default {
    components: {
        Header
    },
    data: () => ({
        listMenu: []
    }),
    methods: {
        menu() {
            this.listMenu = [
                {
                    title: 'Mi perfil',
                    router: 'perfil',
                    icon: 'mdi-account-circle'
                },
                {
                    title: 'Productos',
                    router: 'products',
                    icon: 'mdi-format-list-bulleted-square'
                },
                {
                    title: 'Compras',
                    router: 'purchases',
                    icon: 'mdi-cash'
                },
                {
                    title: 'Compras Fallidas',
                    router: 'faileds',
                    icon: 'mdi-cash-remove'
                },
                {
                    title: 'Usuarios',
                    router: 'users',
                    icon: 'mdi-account-lock',
                },
                {
                    title: 'Paises',
                    router: 'currency',
                    icon: 'mdi-home-map-marker',
                },
                {
                    title: 'Configuraciones',
                    router: 'configurations',
                    icon: 'mdi-cog'
                }
            ]
        }
    },
    mounted() {
        this.menu()
    }
}
</script>